<script>
import HorizontalMenu from '../components/general/HorizontalMenu'
import Breadcrumbs from '@/components/general/Breadcrumbs'
export default {
  components: {
    HorizontalMenu,
    Breadcrumbs
  },
  name: 'Settings',
  data () {
    return {
      settingsMenuOptions: [
        { icon: 'mdi-cog', name: 'settings:account', route: 'account.form' },
        { icon: 'mdi-view-quilt', name: 'settings:linked.workspaces', route: 'linked.workspaces.settings' },
        { icon: 'mdi-account', name: 'settings:profile', route: 'settings.profile.form' },
        { icon: 'mdi-web', name: 'settings:language', route: 'language.form' }
      ],
      menuOptions: [
        { to: { name: 'settings.profile.form' }, text: 'settings.menu:profile.form', icon: 'mdi-briefcase-account' },
        { to: { name: 'settings.skills.form' }, text: 'settings.menu:skills.form', icon: 'mdi-head-cog' },
        { to: { name: 'settings.communication.form' }, text: 'settings.menu:communication.form', icon: 'mdi-email' },
        { to: { name: 'settings.form' }, text: 'settings.menu:settings.form', icon: 'mdi-cog' },
        { to: { name: 'settings.terms.form' }, text: 'settings.menu:terms.of.service.form', icon: 'mdi-file-document-edit' }
      ],
      selectedMenu: 0
    }
  },
  watch: {
    '$route.path': {
      handler (search) {
        if (search === '/account/profile') {
          this.selectedMenu = 0
        }
      }
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'home.header.options:profile', href: this.$route.path }]
      return path
    },
    breadcrumbsRoot () {
      return {
        href: this.$route.params.fullPath,
        avatar: this.$route.params.workspaceType !== 'individual' && this.$route.params.workspaceAvatar
      }
    }
  },
  created () {
    if (!this.selectedWorkspace.companies) this.menuOptions.splice(2, 1)
  }
}
</script>
<template>
  <section class="settings-container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" :root="breadcrumbsRoot" />
      <h2>{{ $t('settings.page:profile.title') }}</h2>
    </div>
    <div class="default-content">
      <p>{{ $t('settings.page:profile.description') }}</p>
      <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
      <div class="settings-view">
        <router-view/>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
.settings-container {
  .settings-view {
    margin-top: 42px;
  }
}
</style>
